<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
               [attr.role]="'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="false">
    <mat-toolbar class="d-flex justify-content-between">
      <a href="/">Menu</a>
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">keyboard_arrow_left</mat-icon>
      </button>
    </mat-toolbar>
    <mat-divider></mat-divider>
    <mat-nav-list>
      <div *ngIf="isLoggedIn() && user && !isRefreshingUser">
        <div *ngFor="let item of dashboardLinkList">
          <a *ngIf="!isRefreshingUser && item.shouldDisplay()"
           mat-list-item
           [routerLink]="[item.route]"
           routerLinkActive="list-item-active">
          <mat-icon *ngIf="item.icon" matListIcon class="nav-list-icon">{{item.icon}}</mat-icon>
          {{item.title}}
          </a>
        </div>
      </div>
      <a mat-list-item *ngIf="!isLoggedIn()" href="/login"><mat-icon matListIcon class="nav-list-icon">login</mat-icon>Se connecter</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" style="height: 128px">
      <div class="row full-width ma-0">
        <div class="col-4 d-flex align-items-center">
          <button
            class="big-button"
            type="button"
            aria-label="Toggle sidenav"
            mat-icon-button
            (click)="drawer.toggle()"
            *ngIf="!drawer.opened && isLoggedIn() && !router.url.includes('/properties/')"
          >
            <mat-icon aria-label="Side nav toggle icon" class="big-button-icon">menu</mat-icon>
          </button>
          <button
            class="big-button"
            type="button"
            aria-label="Toggle sidenav"
            mat-icon-button
            (click)="router.navigate(['/properties'])"
            *ngIf="router.url.includes('/properties/')"
          >
            <mat-icon aria-label="Side nav toggle icon" class="big-button-icon">arrow_back_ios</mat-icon>
          </button>
        </div>
        <div class="col-4 d-flex">
          <span class="toolbar-spacer"></span>
          <a href="/"><img src="assets/logo_256.png" alt="Logo" height="172"/></a>
          <span class="toolbar-spacer"></span>
        </div>
        <div class="col-4 d-flex align-items-center">
          <span class="toolbar-spacer"></span>
          <button mat-button *ngIf="isLoggedIn()" (click)="onProfileButtonClick()">
            <div class="button-icon-text-content">
              <mat-icon class="big-button-icon">account_box</mat-icon>
              <span *ngIf="user">{{user.firstname}} {{user.lastname}}</span>
            </div>
          </button>
          <button mat-button *ngIf="isLoggedIn()" (click)="onSignOutButtonClick()">
            <div class="button-icon-text-content">
              <mat-icon class="big-button-icon">logout</mat-icon>
              <span>déconnexion</span>
            </div>
          </button>
        </div>
      </div>
    </mat-toolbar>
    <app-alert></app-alert>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
