import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {AuthenticationService} from './core/services/authentication/authentication.service';
import {ApiService} from './core/services/api/api.service';
import {AlertService} from './core/services/alert/alert.service';
import {Router} from '@angular/router';
import {GenericUser} from './shared/models/api/users/generic-user';
import {MatDialog} from '@angular/material/dialog';
import {ValidationDialogData} from './shared/models/validation-dialog-data';
import {ValidationDialogComponent} from './shared/components/validation-dialog/validation-dialog.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    user?: GenericUser = undefined;
    isRefreshingUser = false;
    title = 'rayagir-key-manager';

    dashboardLinkList = [
    /*  {
        icon: 'account_box',
        route: '/profile',
        title: 'Profil',
        shouldDisplay: () => {
          return true;
        }
      }, */
      {
        icon: 'description',
        route: '/properties',
        title: 'Fiches',
        shouldDisplay: () => {
          return true;
        }
      },
      {
        icon: 'admin_panel_settings',
        route: '/admin',
        title: 'Admin',
        shouldDisplay: () => {
          return this.isUserAdmin();
        }
      }
    ];

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    constructor(
      private breakpointObserver: BreakpointObserver,
      private authenticationService: AuthenticationService,
      private apiService: ApiService,
      private alertService: AlertService,
      public router: Router,
      public validationDialog: MatDialog,
    ) {}

    ngOnInit(): void {
      if (this.isLoggedIn()) {
        this.refreshUser();
      }
    }

    public isLoggedIn(): boolean {
      if (this.authenticationService.isAuth() && !this.user) {
        this.refreshUser();
      }
      return this.authenticationService.isAuth();
    }

    refreshUser(): void {
      if (this.isRefreshingUser) {
        return;
      }
      this.isRefreshingUser = true;
      this.user = undefined;
      this.apiService.getUserMe().subscribe(
        (me) => {
          setTimeout(() => {
            this.user = me;
            this.isRefreshingUser = false;
            this.dashboardLinkList = [...this.dashboardLinkList];
          });
        }, () => {
          this.alertService.error('Une erreur est survenue lors de la récupération de l\'utilisateur actuel');
        }
      );
    }

    isUserAdmin(): boolean {
      return this.user && this.user.type !== 'manager';
    }

    public signOut(): void {
      this.user = undefined;
      this.authenticationService.signOut();
      this.alertService.success('Vous vous êtes déconnecté', true);
      this.router.navigate(['/login']);
    }

    public onProfileButtonClick(): void {
      this.router.navigate(['/profile']);
    }

    public onSignOutButtonClick(): void {
      const data: ValidationDialogData = {
        title: 'Déconnexion ?',
        text: 'Êtes vous sûr de vouloir vous déconnecter ?',
        onValidate: () => {
          this.signOut();
        },
        onDiscard: () => {}
      };
      this.validationDialog.open(ValidationDialogComponent, {
        data
      });
    }
}
