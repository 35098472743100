import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {MainModule} from './modules/main/main.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import {AuthModule} from './modules/auth/auth.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MAT_DATE_LOCALE, MatOptionModule} from '@angular/material/core';
import {ApiInterceptor} from './core/interceptors/api.interceptor';
import {ProfileModule} from './modules/profile/profile.module';
import {PropertiesModule} from './modules/properties/properties.module';
import {MatSortModule} from '@angular/material/sort';
import {AdminModule} from './modules/admin/admin.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
      BrowserModule,
      HttpClientModule,
      AppRoutingModule,
      NgbModule,
      BrowserAnimationsModule,
      LayoutModule,
      MatToolbarModule,
      MatButtonModule,
      MatSidenavModule,
      MatSortModule,
      MatIconModule,
      MatListModule,
      MatMenuModule,
      MatTableModule,
      MatDividerModule,
      MatProgressSpinnerModule,
      MatInputModule,
      MatCardModule,
      MatSlideToggleModule,
      MatSelectModule,
      MatOptionModule,
      CoreModule,
      SharedModule,
      AuthModule,
      PropertiesModule,
      ProfileModule,
      AdminModule,

      MainModule,
    ],
    providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiInterceptor,
        multi: true
      },
      { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
